import React from "react";
import { getText } from "utils/utils";
import { Item } from "components/components";

const Text = () => {
  const text = getText;
  return (
    <div className="Page Text">
      {text.map((item, i) => (
        <Item {...item} key={"item--" + i} />
      ))}
    </div>
  );
};

export default Text;
