import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const Image = (props) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  });
  const [isLoaded, setLoaded] = useState(0);
  const { src, width, height, ratio, srcSet, sizes, alt } = props;

  return (
    <div
      className={isLoaded ? "image is-loaded" : "image"}
      ref={inViewRef}
      style={{
        paddingBottom: `${(ratio ? ratio : height / width) * 100}%`,
      }}
    >
      {inView &&
        (srcSet ? (
          <img
            alt={alt}
            className={"image__src"}
            onLoad={() => setLoaded(true)}
            srcSet={srcSet}
            sizes={sizes}
            src={srcSet ? src : undefined}
          />
        ) : (
          <img
            alt={alt}
            className={"image__src"}
            onLoad={() => setLoaded(true)}
            src={src}
          />
        ))}
    </div>
  );
};

export default Image;
