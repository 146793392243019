import React, { useState, useRef } from "react";

import { Header } from "components/components";
import { About, Images, Text } from "views/views";
import { useScrollPosition, map, getBackImgs } from "utils/utils";

const Layout = () => {
  const [main, setMain] = useState("text");
  const [images, setImages] = useState(false);

  const backImgs = getBackImgs();
  const [position, setPosition] = useState(0);

  const refContainer = useRef(false);

  const isTouch = window.matchMedia("(pointer: coarse)").matches ? true : false;

  const scrollMovement = (e) => {
    if (!isTouch) return false;
    const pos = refContainer.current.getBoundingClientRect().top;
    const totalFrames = 60;
    const current = map([0, 10], [0, 1], pos);
    const currentFrame = pos ? Math.floor(current / totalFrames) : 0;
    const isEven = currentFrame % 2 === 0;
    const currentPos = Math.floor(current - currentFrame * totalFrames);
    const finalPos = !isEven ? totalFrames - currentPos : currentPos;
    setPosition(finalPos);
  };

  useScrollPosition(({ prevPos, currPos }) => {
    if (isTouch) return false;
    const pos = currPos.y;
    const totalFrames = 60;
    const current = map([0, 10], [0, 1], pos);
    const currentFrame = pos ? Math.floor(current / totalFrames) : 0;
    const isEven = currentFrame % 2 === 0;
    const currentPos = Math.floor(current - currentFrame * totalFrames);
    const finalPos = !isEven ? totalFrames - currentPos : currentPos;
    setPosition(finalPos);
  });

  return (
    <div
      className={"Layout" + (images ? " has-images" : "")}
      onScroll={(e) => scrollMovement(e)}
    >
      <Header {...{ main, setMain, images, setImages }} />

      <div className="Back">
        {backImgs.map((item, i) => {
          return (
            <div
              className={"Back__img" + (i - 1 === position ? " is-active" : "")}
              key={"back-img" + i}
              style={{
                backgroundImage: `url(${item})`,
              }}
            />
          );
        })}
      </div>

      <main ref={refContainer}>
        {main === "text" ? <Text /> : main === "about" ? <About /> : ""}
      </main>
      {<Images show={images} />}
    </div>
  );
};

export default Layout;
