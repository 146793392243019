import React, { useState } from "react";

// import { DevGrid } from "utils/utils";

import { Layout, Loading } from "views/views";

function AppInner() {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className={"App"}>
      {!loaded ? <Loading {...{ setLoaded }} /> : <Layout />}
    </div>
  );
}

function App() {
  return <AppInner />;
}

export default App;
