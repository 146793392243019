import React from "react";
import ReactPlayer from "react-player";

const Video = (props) => {
  const { src, height, width, ratio } = props;

  return (
    <div
      className={"video"}
      style={{
        paddingBottom: `${(ratio ? ratio : height / width) * 100}%`,
      }}
    >
      <ReactPlayer
        className={"video__src"}
        url={src}
        loop={true}
        muted={true}
        playing={true}
      />
    </div>
  );
};

export default Video;
