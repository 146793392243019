import React, { useState, useRef, useEffect, createRef } from "react";
import { getImgs, useAnimationFrame, map, clamp } from "utils/utils";
import { Image, Video } from "components/components";

import { useGesture } from "react-use-gesture";

import { ImagesActive } from "views/views";

const ImagesItem = ({
  thumb = false,
  width = 300,
  height = 200,
  type = "image",
  setActive = undefined,
  setHover = undefined,
}) => {
  return (
    <div
      className="Images__item-inner"
      onClick={setActive}
      onMouseEnter={(e) => setHover(e)}
    >
      {type === "video" ? (
        <Video {...{ src: thumb, width, height }} />
      ) : (
        <Image {...{ src: thumb, width, height }} />
      )}
    </div>
  );
};

const Images = ({ show = false }) => {
  const [ignore, setIgnore] = useState(true);
  const isTouch = window.matchMedia("(pointer: coarse)").matches ? true : false;
  const isSmall = window.innerWidth < 700 ? true : false;
  // canvas Size // max scroll
  const refContainer = useRef(false);
  const size = {
    width: isSmall ? 1100 : 4300,
    height: isSmall ? 1500 : 2490,
  };

  const sizeMax = {
    width: size.width - window.innerWidth,
    height: size.height - window.innerHeight,
  };

  //imgs Array
  const imgs = getImgs;
  const arrLength = imgs.length;
  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  // Active Img
  const [active, setActive] = useState(false);

  // move Speed
  const [speed, setSpeed] = useState([0, 0]);

  const bind = useGesture({
    onDrag: (state) => dragThings(state),
    onMove: (state) => moveThings(state),
  });

  const moveThings = ({ xy }) => {
    if (!show && isTouch) {
      return false;
    }
    const posX = map([0, window.innerWidth], [-10, 10], xy[0]);
    const posY = map([0, window.innerHeight], [-10, 10], xy[1]);

    setSpeed([posX, posY]);
  };
  const dragThings = (state) => {
    if (!show && !isTouch) {
      return false;
    }

    const container = refContainer.current;

    const { x, y } = container.getBoundingClientRect();
    const xNow = clamp(x + state.delta[0], -sizeMax.width, 0);
    const yNow = clamp(y + state.delta[1], -sizeMax.height, 0);

    container.style.transform = `translate3d(${xNow}px, ${yNow}px, 0)`;
  };

  useEffect(() => {
    //initial position
    if (ignore) {
      const container = refContainer.current;

      if (container) {
        const { width, height } = container.getBoundingClientRect();
        container.style.transform = `translate3d(-${width / 2}px, -${
          height / 2
        }px, 0)`;
      }
    }
    setIgnore(false);
  }, [refContainer, elRefs, ignore]);

  useAnimationFrame(() => {
    if (ignore || !show || isTouch) {
      return false;
    }

    const container = refContainer.current;
    if (container) {
      const { x, y } = container.getBoundingClientRect();
      const xNow = clamp(x - speed[0], -sizeMax.width, 0);
      const yNow = clamp(y - speed[1], -sizeMax.height, 0);

      container.style.transform = `translate3d(${xNow}px, ${yNow}px, 0)`;
    }
  });

  const setHover = (e) => {
    const container = refContainer.current;
    const parent = e.currentTarget.parentElement;
    if (container && parent) {
      const newZ = parseFloat(container.dataset.zindex) + 1;
      parent.style.zIndex = newZ;
      container.dataset.zindex = newZ;
    }
  };

  return (
    <>
      {active && <ImagesActive {...active} setActive={setActive} />}
      <div className={"Images" + (!show ? " is-hidden" : "")} {...bind()}>
        <div
          className="Images__inner"
          style={{
            width: size.width,
            height: size.height,
          }}
          data-zindex={0}
          ref={refContainer}
        >
          {imgs.map((item, i) => {
            return (
              <div
                className="Images__item"
                ref={elRefs[i]}
                key={"img--" + i}
                style={{
                  top: isSmall ? item.mob_top : item.top,
                  left: isSmall ? item.mob_left : item.left,
                  width: isSmall ? item.width / 2 : item.width,
                  height: isSmall ? item.height / 2 : item.height,
                }}
                data-move={item.move}
              >
                <ImagesItem
                  {...item}
                  setActive={(e) =>
                    setActive({
                      item: item,
                      mouse: [e.screenX, e.screenY],
                    })
                  }
                  setHover={setHover}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Images;
