import React from "react";

const About = () => {
  return (
    <div className="Page About">
      Nebulous Heaps is a publication / research project by{" "}
      <a
        href="http://kolbrunkolbrun.net"
        rel="noopener noreferrer"
        target="_blank"
      >
        Kolbrún Þóra Löve
      </a>
      , featuring a conversation between Kolbrún and philosopher Timothy Morton.
      <br />
      <br />
      It is exhibited as a part of Haustlaukar II / Autumn Bulbs at the
      Reykjavík Art Museum.
    </div>
  );
};

export default About;
