import React, { useState, useEffect } from "react";
import { getBackImgs, getImgs } from "utils/utils";
import ReactPlayer from "react-player";

const Layout = ({ setLoaded = undefined }) => {
  const backImgs = getBackImgs();
  const imgs = getImgs;
  const [imgsLoaded, setImgsLoaded] = useState(0);

  const total = backImgs.length + imgs.length;

  useEffect(() => {
    if (imgsLoaded === total) {
      //   console.log("ehyyyy");
      setLoaded(true);
    }
  }, [imgsLoaded, setLoaded, total]);

  return (
    <div className={"Loading"}>
      <div className="spinner">
        <span className="spinner-inner-1"></span>
        <span className="spinner-inner-2"></span>
        <span className="spinner-inner-3"></span>
      </div>

      {backImgs.map((item, i) => {
        return (
          <img
            alt=""
            key={"load-back-img" + i}
            className={"Loading__img"}
            onLoad={(e) => setImgsLoaded(imgsLoaded + 1)}
            src={item}
          />
        );
      })}
      {imgs.map(({ thumb = "", type = "image" }, i) => {
        if (type === "video") {
          return (
            <ReactPlayer
              key={"load-back-img" + i}
              className={"Loading__img"}
              url={thumb}
              loop={true}
              muted={true}
              playing={true}
              onReady={(e) => setImgsLoaded(imgsLoaded + 1)}
            />
          );
        } else {
          return (
            <img
              alt=""
              key={"load-back-img" + i}
              className={"Loading__img"}
              onLoad={(e) => setImgsLoaded(imgsLoaded + 1)}
              src={thumb}
            />
          );
        }
      })}
    </div>
  );
};

export default Layout;
