import React from "react";

const Header = ({ main, setMain, images, setImages }) => {
  return (
    <div className="Header">
      <ul>
        <li>
          <div
            onClick={(e) => setMain(main !== "text" ? "text" : false)}
            className={"Header__link" + (main === "text" ? " is-active" : "")}
          >
            Text
          </div>
        </li>
        <li>
          <div
            onClick={(e) => setImages(!images)}
            className={"Header__link" + (images ? " is-active" : "")}
          >
            Images
          </div>
        </li>
        <li>
          <div
            onClick={(e) => setMain(main !== "about" ? "about" : false)}
            className={"Header__link" + (main === "about" ? " is-active" : "")}
          >
            About
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
