import React, { useState, useEffect, createRef } from "react";
import useMeasure from "react-use-measure";

import { getRandomBotText } from "utils/utils";
import { Image, Video } from "components/components";

const ImagesActive = ({ item = [], mouse = [0, 0], setActive = undefined }) => {
  const isTouch = window.matchMedia("(pointer: coarse)").matches ? true : false;

  const [refWindow, boundsWindow] = useMeasure();

  const {
    src = false,
    thumb = false,
    width = 300,
    height = 200,
    type = "image",
  } = item;
  const [botText, setBotText] = useState(false);
  const [ignore, setIgnore] = useState(false);

  const ratio = height / width;
  const ratioWindow = boundsWindow.height / boundsWindow.width;

  const widthInner =
    ratio > ratioWindow
      ? (width * boundsWindow.height) / height
      : boundsWindow.width;

  const [elRefs, setElRefs] = useState(false);

  useEffect(() => {
    if (!botText) {
      const botTextGrab = getRandomBotText();
      setBotText(botTextGrab);
    }
    if (!isTouch) {
      // add or remove refs
      if (!elRefs && botText) {
        setElRefs((elRefs) =>
          Array(botText[0].length)
            .fill()
            .map((_, i) => elRefs[i] || createRef())
        );
      }

      if (elRefs.length && !ignore) {
        makeBanner();
        setIgnore(true);
      }
    }
  });

  const makeBanner = (e) => {
    if (isTouch) return false;
    const kern = 0;
    let pos = 0;
    for (let i = 0; i < elRefs.length; i++) {
      const el = elRefs[i].current;
      if (el) {
        const elWidth = el.getBoundingClientRect().width;
        el.style.left = pos + "px";
        el.style.transform = `translate3d(${mouse[0]}px, ${mouse[1]}px, 0)`;
        pos = pos + elWidth + kern;
      }
    }
  };

  const moveMouse = (e) => {
    if (isTouch) return false;
    const x = e.clientX;
    const y = e.clientY;
    for (let i = 0; i < elRefs.length; i++) {
      const el = elRefs[i].current;
      if (el) {
        setTimeout(() => {
          el.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        }, i * 12);
      }
    }
  };

  const isSmall = window.innerWidth < 700 ? true : false;
  const itemSrc = isSmall ? thumb : src;

  return (
    <div className="ImagesActive" onMouseMove={(e) => moveMouse(e)}>
      <div className="ImagesActive__window" ref={refWindow} />
      {botText && (
        <div className={"ImagesActive__cursor" + (isTouch ? " is-touch" : "")}>
          {!isTouch
            ? botText[0].map((letter, i) => {
                return (
                  <div
                    key={"letter--" + i}
                    ref={elRefs[i]}
                    dangerouslySetInnerHTML={{
                      __html: letter === " " ? "&nbsp;" : letter,
                    }}
                  />
                );
              })
            : botText[1]}
        </div>
      )}
      <div className="ImagesActive__back" onClick={(e) => setActive(false)} />
      <div className="ImagesActive__inner" style={{ width: widthInner + "px" }}>
        {type === "video" ? (
          <Video {...{ src: itemSrc, ratio }} />
        ) : (
          <Image {...{ src: itemSrc, ratio }} />
        )}
      </div>
    </div>
  );
};

export default ImagesActive;
