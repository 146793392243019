import React from "react";

const Item = ({ speaker = "", content = "" }) => {
  return (
    <div className={"Item " + (speaker && "Item--" + speaker)}>
      {speaker}: {content}
    </div>
  );
};

export default Item;
